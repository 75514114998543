import React from 'react';
import LayoutLogout from '../features/_layout/LayoutLogout'
import Auth from '../features/auth/'

export default () => {

  return(
    <LayoutLogout>
      <Auth />
    </LayoutLogout>
  );
};
